import { NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
// @ts-ignore
import * as variants from '@taager-experience-shared/variants';
import { ProductCheckoutModel } from 'app/core/domain/products/product-checkout';
import {
  Analytics,
  ProductAdditionalInfo,
  ProductBasicInfo,
  ProductVariantsData,
  Variant,
  VariantGroup,
  VariantSalesModel,
} from 'src/app/core/domain/products/product-detail.model';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import {
  QUANTITY_DISCOUNT,
  WEB_MARKETPLACE_V1,
} from 'src/app/presentation/shared/constants/feature-flags';
import { featureAttributeAssign } from 'src/app/presentation/shared/utilities/feature-attribute-assign.utility';
import { ProductActionsComponent } from '../product-actions/product-actions.component';
import { ProductDetailsAdditionalInfoComponent } from '../product-details-additional-info/product-details-additional-info.component';
import { ProductDetailsBasicInfoComponent } from '../product-details-basic-info/product-details-basic-info.component';
import { ProductDetailsGalleryComponent } from '../product-details-gallery/product-details-gallery.component';

@Component({
  selector: 'app-product-details-preview',
  templateUrl: './product-details-preview.component.html',
  styleUrls: ['./product-details-preview.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    ProductDetailsGalleryComponent,
    ProductDetailsBasicInfoComponent,
    ProductActionsComponent,
    ProductDetailsAdditionalInfoComponent,
  ],
})
export class ProductDetailsPreviewComponent implements OnInit, OnChanges {
  @ViewChild(ProductDetailsAdditionalInfoComponent)
  appAdditionalInfo: ProductDetailsAdditionalInfoComponent;

  @ViewChild(ProductDetailsGalleryComponent) appProductGallery: ProductDetailsGalleryComponent;

  public productGallery: string[] = [];

  public productAvailability: string;

  public productUrl: string;

  public variantPossibleAttributes: any;

  public colorVariantArr = [];

  public sizeVariantArr = [];

  public colorNamesArray: any;

  public selectedColor: string;

  public selectedSize: string;

  public variantsData: ProductVariantsData;

  public isDiscountOnQuantityEnabled = false;

  public isMarketplaceEnabled = false;

  public hasMultipleVariants = false;

  public productCheckoutDetail: ProductCheckoutModel;

  @Input() productIsCataloged: Boolean;

  @Input() bulkPreOrderButtonIsVisible: Boolean;

  @Input() productIsOrderable: Boolean;

  @Input() currentProduct: Variant;

  @Input() currentVariantGroup: VariantGroup;

  @Input() currency = 'جنيه مصري';

  @Input() flashSaleTitle: string;

  @Input() userHasStore: boolean;

  @Input() productAddedToStore: boolean;

  @Input() storeProvider: string;

  @Input() userHasStockAvailability: boolean;

  @Input() showStockAvailability: boolean;

  @Input() stockAvailabilityStatus: string;

  @Input() stockAvailabilityRunRate: string;

  @Input() isDiscountAsSecondProduct: boolean;

  @Input() isPriceRangeEnabled: boolean;

  @Input() skuAnalyticsEnabled: boolean;

  @Input() skuAnalytics: Analytics;

  product: Variant;

  variantGroup: VariantGroup;

  @Output() addToCart: EventEmitter<void> = new EventEmitter();

  @Output() openBulkRequestDialog: EventEmitter<void> = new EventEmitter();

  @Output() orderNow: EventEmitter<number> = new EventEmitter();

  @Output() toggleIsCataloged: EventEmitter<void> = new EventEmitter();

  @Output() variantChange: EventEmitter<string> = new EventEmitter();

  @Output() changeDisplayedAdditionalInfo: EventEmitter<string> = new EventEmitter();

  @Output() downloadedImages: EventEmitter<void> = new EventEmitter();

  @Output() downloadedVideos: EventEmitter<void> = new EventEmitter();

  @Output() reviewProduct: EventEmitter<void> = new EventEmitter();

  @Output() addProductToStore: EventEmitter<void> = new EventEmitter();

  public basicInfo: ProductBasicInfo;

  public saleInfo?: VariantSalesModel;

  public additionalInfo: ProductAdditionalInfo;

  constructor(
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
    private _router: Router,
  ) {
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit(): void {
    if (this.currentProduct && this.currentVariantGroup) {
      this.product = this.currentProduct;
      this.variantGroup = this.currentVariantGroup;
    } else {
      const storedProduct = localStorage.getItem('product');
      const storedVariantGroup = localStorage.getItem('variantGroup');
      const storedCurrency = localStorage.getItem('currency');
      this.product = storedProduct && JSON.parse(storedProduct);
      this.variantGroup = storedVariantGroup && JSON.parse(storedVariantGroup);
      this.currency = storedCurrency || 'جنيه مصري';
    }
    this.initializeProduct();

    this._getFeatureAttributeUseCase.execute(QUANTITY_DISCOUNT).subscribe({
      next: (flag) => {
        this.isDiscountOnQuantityEnabled = featureAttributeAssign(flag, user.id, country.code);
      },
    });
    this.shouldEnableMarketPlace();
  }

  public shouldEnableMarketPlace(): void {
    this._getFeatureAttributeUseCase.execute(WEB_MARKETPLACE_V1).subscribe({
      next: (flag) => {
        this.isMarketplaceEnabled = featureAttributeAssign(flag, user.id, country.code);
      },
    });
  }

  ngOnChanges(): void {
    if (this.variantGroup && this.currentProduct) {
      this.product = this.currentProduct;
      this.initializeProduct();
    }
  }

  onDisplayedAdditionalInfoChange(tabName: string): void {
    this.changeDisplayedAdditionalInfo.emit(tabName);
  }

  onImagesDownloaded(): void {
    this.downloadedImages.emit();
  }

  onVideosDownloaded(): void {
    this.downloadedVideos.emit();
  }

  initializeProduct(): void {
    this.productGallery = [
      this.product.productPicture,
      this.product.extraImage1,
      this.product.extraImage2,
      this.product.extraImage3,
      this.product.extraImage4,
      this.product.extraImage5,
      this.product.extraImage6,
      ...this.product.additionalMedia,
    ].filter((element) => element);
    this.basicInfo = {
      country: this.product.country,
      productName: this.product.productName,
      productId: this.product.prodID,
      productPrice: this.product.productPrice,
      productProfit: this.product.productProfit,
      Category: this.product.Category,
      currency: this.currency,
      productPriceRange: this.product.pricingRange,
      type: this.product.type,
      bundleVariants: this.product.bundleVariants,
    };

    this.additionalInfo = {
      productEmbeddedVideos: this.product.embeddedVideos,
      productDescription: this.product.productDescription,
      productSpecifications: this.product.specifications,
      productHowToUse: this.product.howToUse,
      productKeywords: this.product.keywords || [],
    };

    if (this.product.sale) {
      this.saleInfo = this.product.sale;
    } else {
      this.saleInfo = undefined;
    }

    this.productAvailability = this.product.productAvailability;

    this.productUrl = window.location.href;

    this.setupProductVariants();

    this.updateVariantsData();
  }

  onAddToCart(): void {
    this.addToCart.emit();
  }

  onAddProductToStore(): void {
    this.addProductToStore.emit();
  }

  onOpenBulkRequestDialog(): void {
    this.openBulkRequestDialog.emit();
  }

  onOrderNow(numberOfItems: number): void {
    this.orderNow.emit(numberOfItems);
  }

  onToggleIsCataloged(): void {
    this.toggleIsCataloged.emit();
  }

  onReviewProduct(): void {
    this.reviewProduct.emit();
  }

  onCustomizeLanding(): void {
    this.appAdditionalInfo.onSelectTab(5);
  }

  onCheckoutProductPixelID(pixelId: string): void {
    this.appProductGallery.updateProductCheckout({ pixelId });
  }

  onCheckoutProductForm(formValues: {
    pixelId?: string;
    isAddressFormLong?: boolean;
    description?: string;
  }): void {
    this.appProductGallery.updateProductCheckout(formValues);
  }

  onProductCheckoutRead(event: ProductCheckoutModel): void {
    this.productCheckoutDetail = event;
  }

  setupProductVariants(): void {
    if (this.variantGroup.attributeSets.length > 0) {
      this.initializeAllPossibleVariantPairs();
      const uniqVariantColors = this.variantPossibleAttributes
        .map((attribute: any) => attribute.color)
        .filter((_color: any, index: number, arr: any) => _color && arr.indexOf(_color) === index);
      if (uniqVariantColors.length > 0) {
        this.colorVariantArr = uniqVariantColors;
        this.colorNamesArray = this.colorVariantArr.map((color) => {
          return variants.getColorByHexCode(color)?.arabicName || '';
        });
        const filteredColor = this.product.attributes.filter(
          (attribute) => attribute.type === 'color',
        )[0];
        this.selectedColor = filteredColor ? filteredColor.value : '';
      }
      this.resetSizes();
      const filteredSize = this.product.attributes.filter(
        (attribute) => attribute.type === 'size',
      )[0];
      this.selectedSize = filteredSize ? filteredSize.value : '';
    }
    this.hasMultipleVariants = this.variantGroup?.variants?.length > 1;
  }

  initializeAllPossibleVariantPairs(): void {
    this.variantPossibleAttributes = this.variantGroup.variants.map((variant) => {
      const filteredColor = variant.attributes.filter((set) => set.type === 'color')[0];
      const filteredSize = variant.attributes.filter((attribute) => attribute.type === 'size')[0];
      return {
        color: filteredColor && filteredColor.value,
        size: filteredSize && filteredSize.value,
      };
    });
  }

  resetSizes(): void {
    const colorPossibleAttributes = this.variantPossibleAttributes.filter(
      (pair: any) => pair.color === this.selectedColor,
    );
    if (colorPossibleAttributes.length > 0) {
      this.sizeVariantArr = colorPossibleAttributes
        .map((pair: any) => pair.size)
        .filter((_size: any, index: number, arr: any) => _size && arr.indexOf(_size) === index);
    } else {
      this.sizeVariantArr = this.variantPossibleAttributes
        .filter((pair: any) => pair.size === this.selectedSize)
        .map((pair: any) => pair.size)
        .filter((_size: any, index: number, arr: any) => _size && arr.indexOf(_size) === index);
    }
  }

  updateProductInLocalStorage() {
    const selectedProduct = this.variantGroup.variants.filter(
      (variant) => variant._id === this.getVariantIdBySelectedAttributtes(),
    )[0];
    this.product = selectedProduct;
    localStorage.setItem('product', JSON.stringify(this.product));
    this.initializeProduct();
  }

  onSelectVariant(selectedVariantAttribute: { color: string; size: string }): void {
    const { color, size } = selectedVariantAttribute;
    if (color) {
      this.selectedColor = color;
    } else if (size) {
      this.selectedSize = size;
    }
    if (this.currentProduct && this.currentVariantGroup) {
      this.variantChange.emit(this.getVariantIdBySelectedAttributtes());
    } else {
      this.updateProductInLocalStorage();
    }
  }

  getVariantIdBySelectedAttributtes(): string | undefined {
    const selectedAttributtes = {
      size: this.selectedSize,
      color: this.selectedColor,
    };
    const sameColorVariants = this.variantGroup.variants.filter((variant) => {
      const variantColorAttributes = variant.attributes.filter(
        (attribute) => attribute.type === 'color',
      );
      return (
        variantColorAttributes[0] && variantColorAttributes[0].value === selectedAttributtes.color
      );
    });
    const sameSizeVariants = this.variantGroup.variants.filter((variant) => {
      const variantSizeAttributes = variant.attributes.filter(
        (attribute) => attribute.type === 'size',
      );
      return (
        variantSizeAttributes[0] && variantSizeAttributes[0].value === selectedAttributtes.size
      );
    });
    if (sameColorVariants.length > 0 && selectedAttributtes.size) {
      const variantWithSameColorAndSize = sameColorVariants.filter(
        (variant) => variant.attributes[1].value === selectedAttributtes.size,
      )[0];
      if (variantWithSameColorAndSize) {
        return variantWithSameColorAndSize._id;
      }
      return sameColorVariants[0]._id;
    }
    if (sameSizeVariants.length > 0) {
      return sameSizeVariants[0]._id;
    }
    return sameColorVariants[0]._id;
  }

  updateVariantsData(): void {
    this.variantsData = {
      colorVariantArr: this.colorVariantArr,
      colorNamesArray: this.colorNamesArray,
      sizeVariantArr: this.sizeVariantArr,
      selectedColor: this.selectedColor,
      selectedSize: this.selectedSize,
    };
  }
}
